.root{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 78%);
    z-index: 99999
}
.image {
    position: absolute;
    top: 39%;
    left: 0;
    width: 100px;
    height: 100px;
    margin: -60px 0 0 -60px;
   
    right: 0;
    margin: 0 auto;
    display: block;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.method {
    position: relative;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    color: transparent;    
    
    margin-top: 70px;

  }
  .method:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    color: #082D59;
    font-weight: bold;
    animation: fill-1 2s infinite;

  }

  @keyframes fill-1 {
    50% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }
@font-face {
  font-family: 'Museo300';
  font-style: normal;
  font-weight: normal;
  src: local('Museo300'),url(./assets/fonts/Museo300-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Museo700';
  src: local('Museo700'), url(./assets/fonts/Museo700-Regular.otf) format("truetype");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.home,
.signupwithgoogle,
.services,
.products,
.sign-up,
.signup,
.login {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  font-size: 3rem;
  background-image: url('./assets/images/Signupbg.png');

}
.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.sign-up {
  background-image: url('./assets/images/Signupbg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* color: #fff; */
  /* font-size: 100px; */
}
/* sigin form CSS */
.container{
    display:flex;
    align-items:center;
    justify-content:space-around;
    /* padding: 0 50px; */
    font-size:14px;
    /* height: 80%; */
}
.form-control {
  border-radius: 50%;
  /* width: 200px; */
      flex-direction:column;
      justify-content:flex-start;
      /* opacity:0.5; */
      border-radius: "50px";
  }
.header-form{
  margin-bottom:15px;
}
button{
  margin-top:40px;
  margin-bottom:10px;
}
/* .message{
  display:flex;
  justify-content:space-between
}
.social{
  display:flex;
  justify-content:space-around;
  padding:30px;
}
.social i{
  font-size:35px;
}
.social i:hover{
  color:red;
  cursor:pointer;
} */
/* .login {
  background-color: #1E3A71;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */
.signupwithgoogle {
  background-color: #1E3A71;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.signup {
  background-color: #1E3A71;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td{
  border: 1px solid #766c6c;
  text-align: left;
  padding: 8px;
}
.th {
  border: 1px solid #766c6c;
  text-align: left;
  padding: 8px;
}

/* .buttonSign{
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
} */




.filepond--item {
    width: calc(33% - 0.5em);
    height: 200px !important;
    overflow: hidden;
}

.filepond--root {
    max-height: 12em;
}

.paragraphHtml {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    /* fallback */
    max-height: 32px;
    /* fallback */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}
.editorContainer {
	padding: 1em;
	margin: 1em;
	margin-top: 5rem;
}

.pageContainer {
	width: 100%;
	margin-top: 4rem;
}

.fileicons {
	height: 50px;
	width: 26px;
	margin: auto;
	text-align: center;
	justify-content: center;
	display: flex;
	padding: 1px;
}

.boxicon {
	margin: auto;
	height: 50px;
	color: white;
	width: 50px;
	border-radius: 26px;
	background: #cbd0de;
}

.iconname {
	color: #1E3B72;
	font-family: Museo300;
	font-size: 18px;
	display: flex;
	justify-content: center !important;
	text-align: center !important;
	margin: auto;
	padding: 5px;
	padding-bottom: 22px;
}

.editors {
	text-align: left;
	border: none;
	padding: 2em;
	border-radius: 8px;
	margin: 2em;
	font-family: "Open Sans";
	font-size: 100%;
	background-image: linear-gradient(to bottom, #fdfbfb 0%, #f6f7fb 100%);
	height: 80vh;
}

@media only screen and (max-width: 1024px) {
	.editors {
		text-align: left;
		border: none;
		padding: 2em;
		border-radius: 8px;
		margin: 2em;
		font-family: "Open Sans";
		font-size: 100%;
		background-image: linear-gradient(to bottom, #fdfbfb 0%, #f6f7fb 100%);
		height: 53vh;
	}
}

.righteditors {
	text-align: right;
	border: none;
	padding: 2em;
	border-radius: 8px;
	margin: 2em;
	font-family: "Open Sans";
	font-size: 100%;
	background-image: linear-gradient(to bottom, #fdfbfb 0%, #f6f7fb 100%);
	height: 80vh;
}

.RichEditor-activeButton {
	background-color: #feb47b;
	color: white;
}


.styleButton#bold {
	font-weight: bold;
}

.styleButton#italic {
	font-style: italic;
	font-family: serif;
}

.styleButton#underline {
	text-decoration: underline;
}

.toolbar {
	text-align: center;
	margin: 0.5em;
	padding: 0.5em;
}

.styleButton,
.RichEditor-styleButton {
	padding: 0.5em;
	background-image: linear-gradient(to bottom, #fdfbfb 0%, #ebedee 100%);
	border: none;
	margin: 0.35em;
	width: 2.75em;
	font-family: "Open Sans";
	font-size: 13px;
	height: 2.25em;
	border-radius: 4px;
}

.RichEditor-activeButton {
	background-color: #feb47b;
	color: white;
}

.toolbars {
	display: flex !important;
	flex-flow: column !important;
}

.RichEditor-controls {
	display: flex !important;
	flex-flow: column !important;
	align-items: center;
}

@media only screen and (max-width: 667px) {
	.RichEditor-controls {
		display: flex !important;
		flex-flow: row !important;
		align-items: center;
	}
}

@media only screen and (max-width: 1024px) {
	.RichEditor-controls {
		display: inline-flex;
		flex-flow: row !important;
		align-items: center;
	}
}

span.RichEditor-controls select {
	width: 60%;
	display: block;
	/* margin-left: auto;
    margin-right: auto; */
}

@media only screen and (max-width: 667px) {
	span.RichEditor-controls select {
		width: 28%;
		display: block;
		/* margin-left: auto;
		margin-right: auto; */
	}
}

figure {
	max-width: 100% !important;
	max-height: 70vh !important;
	overflow: hidden;
	padding: 10
}

.awlhfjh {
	margin-top: '30px !important'
}